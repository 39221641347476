<template>
  <layout-editor>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

  </layout-editor>
</template>

<script>
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import LayoutEditor from '@core/layouts/layout-editor/LayoutEditor.vue'

export default {
  components: {
    LayoutEditor,
    AppCustomizer,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
