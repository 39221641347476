<template>
  <b-card-body>
    <div
      class="mt-1 mb-0 w-100"
      :class="rows ? '' : 'mx-1'"
    >
      <draggable
        v-model="localItems"
        tag="span"
        class="m-0 row mb-1"
        :class="rows ? 'row-cols-1' : 'card-deck row-cols-3'"
        handle=".move-handle"
      >
        <!--      <b-col-->
        <!--          v-for="(item, i) in localItems"-->
        <!--          :key="`block-item-${i}`"-->
        <!--      >-->
        <component
          :is="rows ? 'section-block-item-row' : 'section-block-item'"
          v-for="(item, i) in localItems"
          :key="`block-item-${i}`"
          :item="item"
          :style="(rows ? 'width: 100%' : 'min-width: 25%;') + (getLocale() === 'ar' ? 'direction: rtl;' : '')"
          :class="{
            rtl: getLocale() === 'ar'
          }"
          @edit="onItemEditClick(item)"
          @deleted="$emit('deleted', item.block_id)"
        />
      <!--      </b-col>-->
      </draggable>
      <b-card
        v-if="!localItems.length"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        border-variant="success"
        bg-variant="transparent"
        class="shadow-none d-flex cursor-pointer text-secondary"
        align="center"
        @click="isSidebarActive=true"
      >
        <div class="text-secondary">
          <!-- img -->
          <material-icon
            icon="add_box"
            size="64"
            color="#c0c0c0"
          />
          <!--/ img -->
          <h4>{{ $t('modules.layout_settings.actions.add_item_title') }}</h4>
          <b-card-text>
            {{ $t('modules.layout_settings.actions.add_item_subtitle') }}
          </b-card-text>
        </div>
      </b-card>
    </div>

    <b-button
      v-if="localItems.length"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-success"
      block
      @click="isSidebarActive=true"
    >
      <material-icon
        icon="add_box"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('modules.layout_settings.actions.add_item_title') }}</span>
    </b-button>
    <sidebar-add-new-block-item
      :is-sidebar-active.sync="isSidebarActive"
      :schema="schema"
      :item-id.sync="sidebarItemId"
      :block-name="blockName"
      :template-id="templateId"
      :section-id="sectionId"
      @refetch-data="$emit('submit')"
      @section-updated="section => $emit('section-updated', section)"
    />
  </b-card-body>
</template>

<script>
import {
  BCard, BCardText, BButton, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import SidebarAddNewBlockItem from '@/views/models/settings/layout-settings/SidebarAddNewBlockItem.vue'
import SectionBlockItem from '@/views/models/settings/layout-settings/SectionBlockItemV2.vue'
import { getLocale } from '@core/utils/utils'
import themeBlockItemModel from '@/views/models/settings/layout-settings/themeBlockItemModel'
import { onMounted, onUnmounted } from 'vue'
import store from '@/store'
import themeBlockItemStoreModule from '@/views/models/settings/layout-settings/themeBlockItemStoreModule'
import SectionBlockItemRow from '@/views/models/settings/layout-settings/SectionBlockItemRow.vue'
/* eslint-disable global-require */
export default {
  components: {
    SectionBlockItem,
    SectionBlockItemRow,
    SidebarAddNewBlockItem,
    draggable,
    BCardBody,
    BCardText,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  emits: ['section-updated', 'sorting', 'deleted'],
  props: {
    templateId: {
      type: Number,
      required: true,
    },
    sectionId: {
      type: [Number, String],
      required: true,
    },
    schema: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    blockName: {
      type: String,
      default: null,
    },
    rows: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      isSidebarActive: false,
      sidebarItemId: null,
      saveSortingOnLocalItemsUpdate: true,
      localItems: this.items || [
        {
          block_img: require('@/assets/images/illustration/Pot1.svg'),
          block_heading: 'Basic',
          block_sub_heading: 'A simple start for everyone',
        },
        {
          block_img: require('@/assets/images/illustration/Pot1.svg'),
          block_heading: 'Item 2',
          block_sub_heading: 'This is the description of it',
        },
      ],
    }
  },
  watch: {
    items(newValue) {
      this.silentUpdateLocalItems(newValue)
    },
    localItems(newValue) {
      if (this.saveSortingOnLocalItemsUpdate) {
        this.$emit('sorting', newValue.map(item => item.block_id))
      }
    },
  },
  created() {
  },
  methods: {
    getLocale,
    onItemEditClick(item) {
      this.sidebarItemId = item.block_id
      this.isSidebarActive = true
    },
    silentUpdateLocalItems(newValue) {
      this.saveSortingOnLocalItemsUpdate = false
      this.localItems = newValue
      this.$nextTick(() => {
        this.saveSortingOnLocalItemsUpdate = true
      })
    },
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = themeBlockItemModel.name

    // Register module
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, themeBlockItemStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    return {
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
</style>
